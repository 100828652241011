/*
@font-face {
  font-family: 'example';
  font-weight: normal;
  font-style: normal;
  src: local('example'),
      url('./fonts/example.woff2') format('woff2'),
      url('./fonts/example.woff') format('woff'),
      url('./fonts/example.ttf') format('truetype');
}
*/

@font-face {
  font-family: 'Maison Neue Demi';
  font-weight: normal;
  font-style: normal;
  src: local('MaisonNeue-Demi'),
      local('Maison Neue Demi'),
      url('./fonts/MaisonNeue-Demi.woff2') format('woff2'),
      url('./fonts/MaisonNeue-Demi.woff') format('woff'),
      url('./fonts/MaisonNeue-Demi.ttf') format('truetype');
}

@font-face {
  font-family: 'Mrs Eaves XL';
  font-weight: normal;
  font-style: normal;
  src: local('MrsEavesXLSerifOT-Reg'),
      local('Mrs Eaves XL'),
      url('./fonts/MrsEavesXLSerifOT-Reg.woff2') format('woff2'),
      url('./fonts/MrsEavesXLSerifOT-Reg.woff') format('woff'),
      url('./fonts/MrsEavesXLSerifOT-Reg.ttf') format('truetype');
}

html {
  font-family: 'Mrs Eaves XL', Times, serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body {
  /* letter-spacing: 0.025rem; */
  font-size: 18px;
  line-height: 1.3rem;
  /* line-height: 1.35rem; */
}

a {
  color: hsla(0, 2%, 10%, 1.0);
}


h1,h2,h3,h4,h5,h6 {
  font-weight: normal !important;
  font-family: 'Maison Neue Demi', Arial, sans-serif;
  line-height: 1.8rem;
}

/* h1 {
  font-size: 3em;
  line-height: 1.2em;
}
@media (max-width: 400px) {
  h1 {
    font-size: 2.5em;
  }
} */

/* h1 {
  font-size: 3em;
  line-height: 1.2em;
}
h2 {
  font-size: 2em;
  line-height: 1.2em;
  font-weight: 500;
  padding-bottom: 0.5em;
}
@media (max-width: 400px) {
  h1 {
    font-size: 2.2em;
  }
  h2 {
    font-size: 1.8em;
  }
} */